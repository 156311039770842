<template>
  <label
    class="relative flex flex-col text-inherit"
    :class="[
      wrapperClass,
      {
        'max-h-12 h-12': tag !== 'textarea'
      }
    ]"
  >
    <input
      v-if="tag === 'input'"
      v-bind="$attrs"
      :id="_ID"
      ref="inputRef"
      v-model="currValue"
      class="h-inherit max-h-inherit"
      :class="[
        component.main,
        inputAreaClass,
        {
          'cursor-pointer': !searchable && modelType === 'dropdown'
        }
      ]"
      :type="type"
      @focus="() => isFocused = 1"
      @blur="($event) => { handleBlur($event), validate($event) }"
      @input="($event) => { handleInput($event), validate($event) }"
      @keydown="handleNumbers"
    />

    <textarea
      v-else-if="tag === 'textarea'"
      v-bind="$attrs"
      :id="_ID"
      ref="inputRef"
      v-model="currValue"
      :rows="rows"
      class="h-inherit max-h-inherit"
      :class="[
        component.main,
        inputAreaClass
      ]"
      :type="type"
      @focus="() => isFocused = 1"
      @blur="handleBlur"
      @input="($event) => { handleInput($event), validate($event) }"
    >
    </textarea>

    <span
      v-if="(!!errorMessage || ['focused', 'error'].includes(status)) && fieldLabel"
      ref="label"
      class="absolute left-4 -top-2 px-1 text-xs leading-[1.125rem] text-white z-[1] whitespace-nowrap"
      :class="component.label"
    >
      {{ fieldLabel }}
    </span>

    <div
      v-if="showIcon"
      ref="icon"
      class="flex items-center justify-center absolute right-2 top-1/2 -translate-y-1/2 w-6 h-6"
      :class="{
        'pointer-events-none': modelType === 'dropdown'
      }"
      @click.prevent.stop
    >
      <IconsCheckedBox
        v-if="
          isSuccess &&
            (
              (modelType === 'dropdown' && hideIcon) ||
              ['text', 'money', 'number', 'date', 'inputNumberOnly', 'inputTextOnly', 'cvv'].includes(modelType) ||
              (props.isEmailDropdown)
            ) &&
            !!currValue
        "
        type="circle"
        fill="#193560"
      />

      <NuxtImg
        v-else-if="type === 'password'"
        ref="password-icon"
        :src="`/icons/${showPassword ? 'eye-closed' : 'eye'}.svg`"
        alt="eye"
        width="20"
        height="14"
        class="cursor-pointer"
        @click="handleToggleShowPassword"
      />

      <slot
        v-else-if="modelType === 'dropdown' && !hideIcon && !isEmailDropdown"
        name="icon"
      >
        <IconsChevron
          ref="arrow"
          class="fill-dark"
          :direction="isFocused ? 'top' : 'bottom'"
          :type="(chevronConfig && chevronConfig.type) || 'default'"
          :size="(chevronConfig && chevronConfig.size) || 'lg'"
        />
      </slot>
    </div>
  </label>
</template>

<script setup>
import dayjs from 'dayjs'

defineOptions({
  name: 'AtomsInput',
  inheritAttrs: false
})

const emit = defineEmits([
  'update:modelValue',
  'update'
])

const $attrs = useAttrs()
const props = defineProps({
  modelValue: {
    type: [String, Array, Object, Number],
    default: ''
  },

  modelType: {
    type: String,
    default: 'text',
    validator: value => ['text', 'money', 'number', 'dropdown', 'date', 'text-only'].includes(value)
  },

  label: {
    type: String,
    default: ''
  },

  theme: {
    type: String,
    default: 'default',
    validator: value => ['default', 'light', 'datepicker'].includes(value)
  },

  dateFormat: {
    type: String,
    default: 'DD/MM/YYYY'
  },

  type: {
    type: String,
    default: 'text',
    validator: value => ['text', 'password', 'number', 'credit-card'].includes(value)
  },

  textarea: {
    type: Boolean,
    default: false
  },

  hideIcon: {
    type: Boolean,
    default: false
  },

  searchable: {
    type: Boolean,
    default: false
  },

  darkPlaceholder: {
    type: Boolean,
    default: false
  },

  lightPlaceholder: {
    type: Boolean,
    default: false
  },

  inputAreaClass: {
    type: String,
    default: ''
  },

  isSuccess: {
    type: Boolean,
    default: false
  },

  errorMessage: {
    type: String,
    default: ''
  },

  chevronConfig: {
    type: Object,
    default: () => ({})
  },

  hasCurrency: {
    type: Boolean,
    default: false
  },

  hasPercentage: {
    type: Boolean,
    default: false
  },

  rows: {
    type: [String, Number],
    default: 3
  },

  validate: {
    type: Function,
    default: $event => $event
  },

  wrapperClass: {
    type: String,
    default: ''
  },

  isEmailDropdown: {
    type: Boolean,
    default: false
  },

  abTestPlaceholder: {
    type: String,
    default: ''
  },

  nonFloat: {
    type: Boolean,
    default: false
  }
})

const _ID = $attrs.id || generateUID($attrs.name)

// variables
// const input = ref(null)
const currValue = proxyModel(props, 'modelValue')
const isFocused = ref(0)
const showPassword = ref(false)
const status = computed(() => {
  if (
    $attrs?.disabled === '' ||
      $attrs?.disabled ||
      $attrs?.['aria-disabled'] === '' ||
      $attrs?.['aria-disabled']
  ) {
    return 'disabled'
  }

  if (isFocused.value) {
    return 'focused'
  }

  if (props.errorMessage) {
    return 'error'
  }

  if (props.isSuccess) {
    return 'success'
  }

  return 'default'
})

const inputRef = ref()

defineExpose({
  isFocused,

  // TODO: remove input
  $el: inputRef,
  input: inputRef
})

const tag = computed(() => (props.textarea ? 'textarea' : 'input'))

const fieldLabel = computed(() => {
  const label = ((isFocused.value && props.label) || props.isSuccess || props.errorMessage)

  if (label === true) {
    return ''
  }

  return label
})

const showIcon = computed(() => {
  return !props.hideIcon &&
      (
        status.value === 'success' ||
          props.theme === 'datepicker' ||
          props.type === 'password' ||
          (
            props.modelType === 'dropdown' &&
              status.value !== 'disabled'
          )
      ) &&
      !props.textarea
})

// styles
const component = computed(() => {
  const type = props.type
  const modelType = props.modelType
  const searchable = props.searchable
  const theme = props.theme
  const darkPlaceholder = props.darkPlaceholder
  const lightPlaceholder = props.lightPlaceholder

  const _status = status.value === 'disabled'
    ? (!!props.errorMessage && 'error') || status.value
    : status.value

  const main = (() => {
    const commonStyles = (() => {
      let temp = 'appearance-none border rounded-lg py-3 w-full outline-0 focus-visible:outline-none text-[inherit]'

      if (modelType === 'text' || searchable) {
        temp += ' focus:placeholder:text-transparent'
      }

      return temp
    })()

    const themeStyles = getKey(theme, {
      default: 'bg-gray-5 border-gray-400',
      light: 'bg-white border-gray-400',
      datepicker: 'pr-10 pl-4 py-3 h-[auto] rounded-lg outline-0 shadow-none bg-gray-5 border-gray-400'
    })

    const statusStyles = getKey(_status, {
      default: themeStyles + ' px-4',
      focused: 'bg-white border-primary px-4 !outline-none',
      error: 'bg-white border-alert-error-stroke pl-4 pr-[2.25rem]',
      success: 'bg-white border-gray-400 pl-4 pr-[2.25rem]',
      disabled: 'bg-gray-350 border-gray-400 px-4'
    })

    const textStyles = (() => {
      const tempStatus = modelType === 'dropdown' && status.value !== 'disabled'
        ? 'dropdown'
        : status.value

      const textMain = getKey(tempStatus, {
        default: 'text-gray-75 placeholder:text-gray-75',
        dropdown: 'text-dark placeholder:text-dark',
        disabled: 'text-gray-40 placeholder:text-gray-40',
        success: 'text-dark',
        error: 'text-dark'
      })

      const placeholder = (() => {
        if (darkPlaceholder) {
          return '!text-dark placeholder:!text-dark'
        }

        if (lightPlaceholder) {
          return '!text-gray-40 placeholder:!text-gray-40'
        }
      })()

      return `${textMain} ${placeholder}`
    })()

    const textarea = props.textarea
      ? 'h-24'
      : ''

    const padding = (() => {
      if (type === 'credit-card') {
        return '!pl-10'
      }

      if (type === 'password') {
        return 'pr-10'
      }

      //! props.hideDropdownIcon &&
      if (modelType === 'dropdown' && status.value !== 'disabled') {
        return 'pr-[2.25rem]'
      }

      return ''
    })()

    return `${commonStyles} ${statusStyles} ${textStyles} ${textarea} ${padding}`
  })()

  const label = (() => {
    return getKey(_status, {
      default: '',
      focused: 'bg-primary',
      error: 'bg-alert-error-stroke'
    })
  })()

  return {
    main,
    label
  }
})

watch(currValue, async () => {
  await nextTick()

  emit('update', currValue.value)

  inputRef.value?.dispatchEvent(new Event('blur'))
}, { deep: true })

if (currValue.value || props.modelValue) {
  emit('update', currValue.value || currValue.value || props.modelValue)
}

onMounted(async () => {
  await nextTick()

  if (props.modelValue) {
    currValue.value = props.modelValue
  }

  if (props.abTestPlaceholder) {
    inputRef.value.placeholder = props.abTestPlaceholder
  }
})

function handleBlur () {
  isFocused.value = 0
}

function handleToggleShowPassword () {
  showPassword.value = !showPassword.value
  inputRef.value.type = showPassword.value ? 'text' : 'password'
}

const validateData = {
  number: $event => {
    const target = $event.target

    const ctrl = $event.ctrlKey || $event.metaKey
    const charCode = $event.which || $event.keyCode

    const key = $event.key
    const code = key && key.charCodeAt()

    const data = `${key}`
      .match(/(\d|.)*$/g)
      .filter(e => !isNaN(e) || e === '.')?.[0] ?? ''

    if (!charCode && !code) {
      return $event.preventDefault()
    }

    /**
     * code:
     * 69 - enter
     * 66 - backspace
     */

    if (
      !data &&
        ![66, 65, 46, 84, 77, 69, 8].includes(code) &&
        !(ctrl && [97, 114, 118].includes(code))
    ) {
      return $event.preventDefault()
    }

    const index = target.value.indexOf('.')
    if (index > 0 && code === 46) {
      return $event.preventDefault()
    }

    return true
  },

  money: $event => {
    let currVal = $event.target.value.toString()

    const key = ($event?.key ? $event.key.toLowerCase() : '') ||
    $event?.inputType
      ? $event.inputType.toLowerCase()
      : ''

    const isDelete = !!(['backspace', 'deletecontentbackward'].includes(key))

    if (currVal[0] === '.') {
      currVal = '0.'
    }

    // returns only 1 zero
    if (Number(currVal[0]) === 0 && Number(currVal[1]) === 0) {
      currVal = 0
    }

    // returns the 2nd index if the 1st index is 0
    if (Number(currVal[0]) === 0 && Number(currVal[1]) > 0) {
      currVal = Number(currVal[1])
    }

    // removes 0 if the next currVal is greater than 0
    if (Number(currVal[0]) === 0 && Number(currVal[1]) > 0) {
      currVal = currVal.split('')
    }

    if (isDelete) {
      if (props.hasPercentage) {
        currVal = currVal.slice(0, -1)
      }

      currVal = currVal.slice(-1) === '.'
        ? currVal.slice(0, -1)
        : currVal
    }

    $event.target.value = currVal

    return $event.target.value
  },

  date: ($event, format) => {
    format = format.split('/')

    const target = $event.target
    const key = ($event?.key ? $event.key.toLowerCase() : '') ||
    $event?.inputType
      ? $event.inputType.toLowerCase()
      : ''

    const isDelete = !!(['backspace', 'deletecontentbackward'].includes(key))
    if(isDelete) return
    const inputTmp = target.value
    if(target.selectionStart !== inputTmp.length) return
    const type = str => {
      if (str === 'DD') {
        return 31
      } else if (str === 'MM' || str === 'mm') {
        return 12
      } else if (str === 'YY') {
        return dayjs().add(15, 'year').format('YY')
      } else if (str === 'yyyy') {
        return dayjs().add(15, 'year').format('yyyy')
      }
    }

    const charCode = $event.which ? $event.which : $event.keyCode
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      $event.preventDefault()
    }
    const values = inputTmp.split('/').map(item => item.replace(/\D/g, ''))
    if (values[0]) {
      values[0] = checkValue(values[0], type(format[0]))
    }

    if (values[1]) {
      values[1] = checkValue(values[1], type(format[1]))
    }

    // handle delete if has separator
    const temp = values.join('/')

    // mm/yyyy
    if (format[1] === 'yyyy' && format[0] === 'mm') {
      const op = temp.split('/').map((item, i) => {
        if (i === 0) {
          return item.length === 2
            ? `${item}/`
            : item
        }

        if (i === 1) {
          return item.length > 4
            ? item.substr(0, 4)
            : item
        }
        return ''
      })

      currValue.value = op.join('')

      return
    }
    // add separator
    const output = temp.split('/').map((item, i) => {
      return item.length === 2 && i < 2
        ? `${item}/`
        : item
    })

    if (format.length === 2) {
      currValue.value = output.join('').substr(0, format.join('').length + 1)
      return
    }
    currValue.value = output.join('').substr(0, 10)
  }
}

/*
 * Handles number input events and prevents invalid key presses.
 *
 * @param {Event} $event - The input event object.
 */
function handleNumbers ($event) {
  if (props.modelType === 'date') {
    handleDateInput($event)
    return
  }
  if (!['number', 'money'].includes(props.modelType)) {
    return
  }

  validateData.number($event)
}

/*
 * Handles date input events and prevents invalid key presses for date inputs.
 *
 * @param {Event} $event - The input event object.
 */
function handleDateInput ($event) {
  // Get the key or input type from the event and convert it to lowercase
  const key = ($event?.key ? $event.key.toLowerCase() : '') || ($event?.inputType ? $event.inputType?.toLowerCase() : '')
  const isBackspace = ['backspace', 'deletecontentbackward'].includes(key)
  const isDelete = ['delete'].includes(key)

  // Prevent default action if backspace should be prevented
  if (isBackspace && shouldPreventBackspace($event)) {
    $event.preventDefault()
  }

  // Prevent default action if delete should be prevented
  if (isDelete && shouldPreventDelete($event)) {
    $event.preventDefault()
  }
}

/*
 * Determines if the backspace key event should be prevented.
 *
 * @param {Event} $event - The input event object.
 * @returns {boolean} - Returns true if the backspace key event should be prevented, otherwise false.
 */
function shouldPreventBackspace ($event) {
  return ($event.target.value.length !== $event.target.selectionStart) && $event.target.value[$event.target.selectionStart - 1] === '/'
}

/*
 * Determines if the delete key event should be prevented.
 *
 * @param {Event} $event - The input event object.
 * @returns {boolean} - Returns true if the delete key event should be prevented, otherwise false.
 */
function shouldPreventDelete ($event) {
  return ($event.target.value.length !== $event.target.selectionStart) && $event.target.value[$event.target.selectionStart] === '/'
}

/**
 * formats number to currency strings
 * @param data {String}
 */
function handleFormatAmount (data) {
  if (props.modelType !== 'money') {
    return
  }

  const number = data.toString()
    .replace(/[,]/g, '')
    .split('.')

  number[0] = number[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  if (props.nonFloat) {
    return number[0]
  }

  return number.join('.')
}

function handleInput ($event) {
  const modelType = props.modelType

  if (modelType === 'money') {
    const amount = handleFormatAmount(validateData.money($event))

    if (props.hasCurrency) {
      currValue.value = (() => {
        if (amount === '$') {
          return ''
        }

        return `${amount}`.includes('$')
          ? amount
          : '$' + amount
      })()

      if (
        currValue.value?.length >= 3 &&
        currValue.value?.charAt(1) === '0'
      ) {
        currValue.value = currValue.value.substring(0, 1) + currValue.value.substring(2)
      }
    } else if (props.hasPercentage) {
      if (amount === '') {
        currValue.value = ''
      } else {
        const temp = `${amount}`.includes('%')
          ? `${amount.replace('%', '')}%`
          : `${amount}%`

        currValue.value = temp
      }
    } else {
      currValue.value = amount
    }

    return
  }

  if (modelType === 'date') {
    validateData.date($event, props.dateFormat)
  }

  if (modelType === 'text-only') {
    currValue.value = currValue.value?.replace(/\d+|^\s+$/g, '').replace(/\s+/g, ' ') || ''
  }
}

function checkValue (str, max) {
  if (str.charAt(0) !== '0' || str === '00') {
    let num = parseInt(str)

    if (isNaN(num) || num <= 0 || num > max) {
      num = 1
    }
    str = num > parseInt(max.toString().charAt(0)) && num.toString().length === 1
      ? '0' + num
      : num.toString()
  }

  return str
}
</script>
